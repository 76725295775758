import { object, number, string, boolean, array } from 'zod';
import { validateCpf } from '~/utils/validCpf';

const uploadedFiles = object({
  id: number(),
  filename: string(),
  size: string().optional(),
  examType: number().optional(),
});

export const requestSchema = object({
  profession: string().optional(),
  specialty: string().optional(),
  profissional: string().optional(),
  specialtySelected: boolean().optional(),
  professionalSelected: boolean().optional(),
  fullName: string().optional(),
  sex: string().optional(),
  birthDate: string().optional(),
  regType: string().optional(),
  regValue: string().optional(),
  motherName: string().optional(),
  phone: string().optional(),
  patientDescription: string().optional(),
  isDisabled: boolean().optional(),
  files: array(uploadedFiles).optional(),
  consultant: number().optional(),

  state: string().nonempty({ message: 'Campo obrigatório' }),
  county: string()
    .nonempty({ message: 'Campo obrigatório' })
    .refine(value => !value.includes('Selecione'), {
      message: 'Selecione seu município',
    }),
  healthUnit: string().nonempty({ message: 'Campo obrigatório' }),
  responsePriority: string().nonempty({ message: 'Campo obrigatório' }),
  description: string().nonempty({ message: 'Campo obrigatório' }),
  modality: string().nonempty({ message: 'Campo obrigatório' }),
});

export const responsibleSchema = object({
  ...requestSchema.shape,
  profession: string().nonempty({ message: 'Campo obrigatório' }),
  specialty: string().nonempty({ message: 'Campo obrigatório' }),
  profissional: string().nonempty({ message: 'Campo obrigatório' }).optional(),
  specialtySelected: boolean(),
  professionalSelected: boolean().optional(),
  consultant: number().optional(),
  files: array(uploadedFiles).optional(),
  orgUnit: number().optional(),
}).refine(
  data => {
    if (data.specialtySelected) {
      return true;
    }

    return data.professionalSelected;
  },
  {
    message: 'Campo obrigatório',
    path: ['profissional'],
  },
);

export const patientSchema = object({
  ...responsibleSchema.shape,
  fullName: string()
    .nonempty({ message: 'Digite ou selecione um paciente' })
    .min(4, { message: 'Digite no mínimo 4 caracteres' }),
  sex: string().nonempty({ message: 'Campo obrigatório' }),
  birthDate: string().nonempty({ message: 'Campo obrigatório' }),
  regType: string().nonempty({ message: 'Campo obrigatório' }),
  regValue: string().nonempty({ message: 'Campo obrigatório' }),
  motherName: string().nonempty({ message: 'Campo obrigatório' }).optional(),
  phone: string().nonempty({ message: 'Campo obrigatório' }),
  isDisabled: boolean().optional(),
  consultant: number().optional(),
  patientDescription: string().optional(),
  files: array(uploadedFiles).optional(),
}).refine(
  data => {
    switch (data.regType) {
      case 'CPF':
        return validateCpf(data.regValue);
      case 'DNV':
        return /^\d{11}$/.test(data?.regValue);
      case 'CNS':
        return /^\d{15}$/.test(data?.regValue);
      case 'RG':
        return true;
      default:
        return false;
    }
  },
  {
    message: 'Documento inválido',
    path: ['regValue'],
  },
);

export const fileSchema = object({
  ...patientSchema.shape,
  files: array(uploadedFiles).optional().nullable(),
});
